import React from "react";
import { Draggable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";

const TaskCard = ({
  item,
  index,
  developers,
  projects,
  handleDeveloperChange,
  handleProjectChange,
  handleHoursChange,
  handleArchiveTask,
  openEditModal,
}) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-white p-4 mb-2 rounded shadow"
        >
          <div className="font-bold text-center">{item.title}</div>
          <div className="text-sm text-gray-600 truncate-2-lines">
            {item.description}
          </div>
          {item.completedAt && (
            <div className="text-xs text-gray-500 mt-1">
              Завершено: {new Date(item.completedAt).toLocaleDateString()}
            </div>
          )}
          <div className="mt-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <select
              value={item.developer || "none"}
              onChange={(e) => handleDeveloperChange(item.id, e.target.value)}
              className="p-1 border rounded w-full md:w-1/3 custom-select"
            >
              <option value="none">Назначить разработчика</option>
              {developers
                .filter((dev) => dev.id !== "all")
                .map((dev) => (
                  <option key={dev.id} value={dev.id}>
                    {dev.name}
                  </option>
                ))}
            </select>
            <select
              value={item.project}
              onChange={(e) => handleProjectChange(item.id, e.target.value)}
              className="p-1 border rounded w-full md:w-1/3 custom-select"
            >
              {projects.map((proj) => (
                <option key={proj.id} value={proj.id}>
                  {proj.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              step="0.1"
              value={item.hours}
              onChange={(e) =>
                handleHoursChange(item.id, parseFloat(e.target.value))
              }
              className="p-1 border rounded w-12 no-arrows"
              placeholder="Часы"
            />
            <div className="flex space-x-2">
              <button
                onClick={() => handleArchiveTask(item._id)}
                className="p-1 bg-red-500 text-white rounded"
              >
                <ArchiveIcon />
              </button>
              <button
                onClick={() => openEditModal(item)}
                className="p-1 bg-blue-500 text-white rounded"
              >
                <EditIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
