import React, { useEffect, useState } from "react";
import axiosInstance from "../api/Axios";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
} from "docx";
import { saveAs } from "file-saver";

// Helper function to format date to month-year
const formatDateToMonthYear = (date) => {
  const options = { year: "numeric", month: "long" };
  return new Date(date).toLocaleDateString("ru-RU", options);
};

// Function to generate Word document
const generateWord = (developerName, tasks, monthYear, totalHours) => {
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: `Задачи для ${developerName} (${monthYear})`,
                bold: true,
                size: 24,
              }),
            ],
          }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({ children: [new Paragraph("Название")] }),
                  new TableCell({ children: [new Paragraph("Описание")] }),
                  new TableCell({ children: [new Paragraph("Проект")] }),
                  new TableCell({ children: [new Paragraph("Часы")] }),
                  new TableCell({ children: [new Paragraph("Создано")] }),
                  new TableCell({ children: [new Paragraph("Завершено")] }),
                ],
              }),
              ...tasks.map(
                (task) =>
                  new TableRow({
                    children: [
                      new TableCell({ children: [new Paragraph(task.title)] }),
                      new TableCell({
                        children: [new Paragraph(task.description)],
                      }),
                      new TableCell({
                        children: [new Paragraph(task.projectName)],
                      }),
                      new TableCell({
                        children: [new Paragraph(task.hours.toString())],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(
                            new Date(task.createdAt).toLocaleString()
                          ),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(
                            task.completedAt
                              ? new Date(task.completedAt).toLocaleString()
                              : "N/A"
                          ),
                        ],
                      }),
                    ],
                  })
              ),
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph("Итого часов:")],
                    columnSpan: 3,
                  }),
                  new TableCell({
                    children: [new Paragraph(totalHours.toString())],
                    columnSpan: 3,
                  }),
                ],
              }),
            ],
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${developerName}_tasks_${monthYear}.docx`);
  });
};

const TaskTable = () => {
  const [groupedTasks, setGroupedTasks] = useState({});
  const [developers, setDevelopers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [archivetasks, setArchivetasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedMonths, setExpandedMonths] = useState({}); // Track expanded/collapsed months

  const fetchData = async () => {
    try {
      const [archivetasksResponse, developersResponse, projectsResponse] =
        await Promise.all([
          axiosInstance.get("/getarchivetasks"),
          axiosInstance.get("/devs"),
          axiosInstance.get("/projects"),
        ]);

      setArchivetasks(archivetasksResponse.data);
      setDevelopers(developersResponse.data);
      setProjects(projectsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const groupTasks = () => {
      const groupedByMonth = archivetasks.reduce((acc, task) => {
        const date = task.completedAt || task.createdAt;
        const monthYear = formatDateToMonthYear(date);
        if (!acc[monthYear]) acc[monthYear] = {};
        if (!acc[monthYear][task.developer])
          acc[monthYear][task.developer] = [];
        acc[monthYear][task.developer].push({
          ...task,
          projectName: getProjectName(task.project),
        });
        return acc;
      }, {});

      // Сортировка задач в каждой группе по дате создания
      for (const month in groupedByMonth) {
        for (const developer in groupedByMonth[month]) {
          groupedByMonth[month][developer].sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
        }
      }

      setGroupedTasks(groupedByMonth);
    };
    groupTasks();
  }, [archivetasks]);

  const getDeveloperInfo = (id) => {
    const developer = developers.find((dev) => dev.id === id);
    return developer ? `${developer.name} (${developer.role})` : id;
  };

  const getProjectName = (id) => {
    const project = projects.find((proj) => proj.id === id);
    return project ? project.name : id;
  };

  const handleRestoreTask = async (taskId) => {
    try {
      await axiosInstance.post(`/restoretask/${taskId}`);
      await fetchData();
    } catch (error) {
      console.error("Error restoring task:", error);
    }
  };

  // New delete function
  const handleDeleteTask = async (taskId) => {
    try {
      await axiosInstance.delete(`/deletetask/${taskId}`);
      await fetchData(); // Update data after deletion
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  // Toggle expanded/collapsed state for a specific month
  const toggleMonth = (month) => {
    setExpandedMonths((prevState) => ({
      ...prevState,
      [month]: !prevState[month],
    }));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-300">
        Loading...
      </div>
    );
  }

  if (archivetasks.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-300">
        <div className="text-xl font-bold">Архив пуст</div>
      </div>
    );
  }

  return (
    <div className="bg-gray-300 min-h-screen p-4 mt-10 sm:mt-0">
      {Object.entries(groupedTasks).map(([month, developers]) => (
        <div key={month} className="mb-8">
          <h2
            className="text-xl font-bold mb-4 cursor-pointer flex items-center select-none"
            onClick={() => toggleMonth(month)} // Toggle the month visibility
          >
            <span className="mr-2">{month}</span>
            <span
              className={`transform transition-transform duration-200 ${
                expandedMonths[month] ? "rotate-180" : ""
              }`}
            >
              ▼
            </span>
          </h2>
          {expandedMonths[month] && // Render the content only if the month is expanded
            Object.entries(developers).map(([developer, archivetasks]) => {
              const totalHours = archivetasks.reduce(
                (sum, task) => sum + task.hours,
                0
              );

              return (
                <div key={developer} className="mb-4">
                  <h3 className="text-lg font-semibold mb-2 flex justify-between items-center">
                    {getDeveloperInfo(developer)}
                    <button
                      onClick={() =>
                        generateWord(
                          getDeveloperInfo(developer),
                          archivetasks,
                          month,
                          totalHours
                        )
                      }
                      className="bg-green-500 text-white px-2 py-1 rounded"
                    >
                      Export to Word
                    </button>
                  </h3>
                  <div className="bg-white shadow-md rounded-lg p-4 overflow-x-auto">
                    <table className="w-full border-collapse text-sm mb-4">
                      <thead>
                        <tr>
                          <th className="border p-2 w-1/6">Название</th>
                          <th className="border p-2 w-1/6">Описание</th>
                          <th className="border p-2 w-1/6">Проект</th>
                          <th className="border p-2 w-1/6">Часы</th>
                          <th className="border p-2 w-1/6">Создано</th>
                          <th className="border p-2 w-1/6">Завершено</th>
                          <th className="border p-2 w-1/6">Действие</th>
                        </tr>
                      </thead>
                      <tbody>
                        {archivetasks.map((task) => (
                          <tr key={task._id}>
                            <td className="border p-2 w-1/6">{task.title}</td>
                            <td className="border p-2 w-1/6">
                              {task.description}
                            </td>
                            <td className="border p-2 w-1/6">
                              {task.projectName}
                            </td>
                            <td className="border p-2 w-1/6">{task.hours}</td>
                            <td className="border p-2 w-1/6">
                              {new Date(task.createdAt).toLocaleString()}
                            </td>
                            <td className="border p-2 w-1/6">
                              {task.completedAt
                                ? new Date(task.completedAt).toLocaleString()
                                : "N/A"}
                            </td>
                            <td className="border p-2 w-1/6 space-y-2">
                              <button
                                onClick={() => handleRestoreTask(task._id)}
                                className="bg-blue-500 text-white px-2 py-1 rounded mb-1 w-full"
                              >
                                Восстановить
                              </button>
                              {/*<button
                                onClick={() => handleDeleteTask(task._id)}
                                className="bg-red-500 text-white px-2 py-1 rounded w-full"
                              >
                                Удалить
                              </button>*/}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3" className="border p-2 font-bold">
                            Итого часов:
                          </td>
                          <td colSpan="4" className="border p-2 font-bold">
                            {totalHours}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
};

export default TaskTable;
